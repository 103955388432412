import "./css/light.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useMediaQuery } from "react-responsive";

import Header from "./components/Header";
import Home from "./components/Home";
import Projects from "./components/projects/Projects";
import Resume from "./components/resume/Resume";
import Subheader from "./components/Subheader";
import Nav from "./components/Nav";
import BlogList from "./components/blog/BlogList";

function App() {
  const isPhone = useMediaQuery({ query: "(max-width: 576px)" });

  return (
    <div className="App">
      {isPhone && <Nav />}
      <Header />
      <Subheader />
      <Container fluid>
        <Row>
          {!isPhone && <Nav />}
          <Col style={{ padding: 0 }}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/projects" element={<Projects />}></Route>
              <Route path="/resume" element={<Resume />}></Route>
              <Route path="/blog" element={<BlogList />}></Route>
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
