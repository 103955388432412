import { Row, Col, Container } from "reactstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTransition, easings, animated } from "@react-spring/web";
import { useMediaQuery } from "react-responsive";
import "../css/home.css";

const Home = () => {
  const theme = useSelector((state) => state.themes.currentTheme);
  const isPhone = useMediaQuery({ query: "(max-width: 576px)" });

  const transition = useTransition(true, {
    from: { opacity: 0.5, transform: "translate3d(60vw, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-100vw, 0, 0)" },
    config: { duration: 400, easing: easings.easeOutSine },
    exitBeforeEnter: true,
  });

  const navigate = useNavigate();
  const navigateToProjects = () => {
    navigate("/projects");
  };
  const navigateToResume = () => {
    navigate("/resume");
  };

  return transition(
    (style, item) =>
      item && (
        <div className={theme}>
          <animated.div
            style={{ ...style, minHeight: "100vh" }}
            className={theme}
          >
            <Container>
              <Row className="animated-card-container">
                <Col
                  className={`animated-card me-5 ${isPhone && "mobile"}`}
                  onClick={navigateToProjects}
                  xs={12}
                  md={5}
                >
                  <p>Projects</p>
                </Col>
                <Col
                  className={`animated-card ${isPhone && "mobile"}`}
                  onClick={navigateToResume}
                  xs={12}
                  md={5}
                >
                  <p>Resume</p>
                </Col>
              </Row>
              <Row className="about">
                <p>
                  As a Full Stack Developer, I love seeing the big picture. I’m
                  always thinking about the end user and how everything, from
                  optimized speed on the backend to elegant design on the
                  frontend, is part of the experience. I’m a big believer in
                  communication and teamwork, and I prefer to work on small,
                  fast-paced teams.
                </p>
              </Row>
            </Container>
          </animated.div>
        </div>
      )
  );
};

export default Home;
