import { useSelector } from "react-redux";
import { useState, useRef, useCallback } from "react";
import {
  useTrail,
  animated,
  useTransition,
  useSprings,
} from "@react-spring/web";
import { Col, Row, Container } from "reactstrap";
import { useMediaQuery } from "react-responsive";
import "../css/header.css";

export default function Subheader() {
  const theme = useSelector((state) => state.themes.currentTheme);
  const isPhone = useMediaQuery({ query: "(max-width: 576px)" });

  const subtitles = [
    {
      text: "Full Stack Developer",
      element: "h2",
      className: "full-stack-developer",
    },
    {
      text: "(1 year experience)",
      element: "h3",
      className: "one-year-experience",
    },
  ];
  const [skills, setSkills] = useState([
    "JavaScript",
    "React",
    "HTML/CSS",
    "Node.js",
    "MongoDB",
    "React Native",
    "Python",
    "Figma",
  ]);
  // const [animationRunning, setAnimationRunning] = useState(false);
  const wrapperRef = useRef();
  const indexRef = useRef();

  const handleRefUpdate = useCallback(
    (node) => {
      if (node !== null && skills.length > 0) {
        wrapperRef.current = node;
        indexRef.current = node.firstChild;
        document.documentElement.style.setProperty(
          "--animationDistance",
          `${0 - indexRef.current.offsetWidth}px`
        );
        document.documentElement.style.setProperty(
          "--animationDuration",
          `${Math.round(indexRef.current.offsetWidth / 33)}s`
        );
        wrapperRef.current.classList.add("moving");
      }
    },
    [skills]
  );

  const handleLoop = () => {
    // wrapperRef.current.classList.remove('moving');
    wrapperRef.current.style.animation = "none";
    const t = wrapperRef.current.offsetHeight; /* trigger reflow */
    wrapperRef.current.style.animation = null;
    shiftNext([...skills]);
  };

  const shiftNext = (copy) => {
    const firstitem = copy.shift();
    copy.splice(copy.length, 0, firstitem);
    setSkills(copy);
  };

  const trail = useTrail(subtitles.length, {
    from: { opacity: 0, transform: "translateX(20px)" },
    to: { opacity: 1, transform: "translateX(0)" },
    config: { duration: 500 },
    delay: 300,
  });

  const Element = (props) => {
    const { title } = props;

    return <div className="element">{title}</div>;
  };

  return (
    <Container fluid className={`subheader ${theme}`}>
      <Row>
        <Col xs={12} md={4} xl={3}>
          {trail.map((props, idx) => {
            const ItemElement = animated[subtitles[idx].element];
            return (
              <ItemElement
                key={idx}
                style={props}
                className={subtitles[idx].className}
              >
                {subtitles[idx].text}
              </ItemElement>
            );
          })}
        </Col>
        <Col className={`skills wrapper ${isPhone && "d-none"}`}>
          {/* <div className="inner" ref={handleRefUpdate} onAnimationEnd={handleLoop}> */}
          <div className="inner">
            {skills.map((skill) => (
              <Element title={skill} key={`skill${skill}`} />
            ))}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
