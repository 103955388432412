import screenshot1 from '../../assets/img/screenshot-wom-1.png';
import screenshot2 from '../../assets/img/screenshot-wom-2.png';
import screenshot3 from '../../assets/img/screenshot-wom-3.png';
import screenshot4 from '../../assets/img/screenshot-wom-4.png';
import screenshot5 from '../../assets/img/screenshot-wom-5.png';
import frScreenshot1 from '../../assets/img/fr-screenshot-1.png';
import frScreenshot2 from '../../assets/img/fr-screenshot-2.png';
import frScreenshot3 from '../../assets/img/fr-screenshot-3.png';
import abScreenshot1 from '../../assets/img/example_1.png';
import abScreenshot2 from '../../assets/img/example_2.png';
import abScreenshot3 from '../../assets/img/example_3.png';
import gmaScreenshot1 from '../../assets/img/gma-screenshot-1.png';
import gmaScreenshot2 from '../../assets/img/gma-screenshot-2.png';
import gmaScreenshot3 from '../../assets/img/gma-screenshot-3.png';
import gmaScreenshot4 from '../../assets/img/gma-screenshot-4.png';
import gmaScreenshot5 from '../../assets/img/gma-screenshot-5.png';
import dataAnnotationScreenshot from '../../assets/img/data-annotation-screenshot.png';
import softwareHarmonicsScreenshot from '../../assets/img/software-harmonics-screenshot.png';
import dwishesScreenshot from '../../assets/img/dwishes-screenshot.png';

export const professionalProjects = [
    {
        id: 'professional0',
        title: 'Mobile Version of ERP Web App',
        client: 'Software Harmonics, LLC',
        clientLink: 'https://softwareharmonics.com/',
        dates: 'Feb. 2024 - Present',
        skillsUsed: ['JavaScript', 'HTML/CSS', 'React', 'Redux', 'MUI', 'Figma'],
        image: {
            src: softwareHarmonicsScreenshot,
            alt: 'Software Harmonics LLC Home Page'
        },
        achievements: [
            'Built a UX/UI plan in Figma with interactive prototype',
            'Researched and implemented camera access on mobile devices',
            'Found a solution for PDF viewing on mobile',
            'Currently collaborating with the team to build a code prototype for the mobile website'
        ]
    },
    {
        id: 'professional1',
        title: 'AI Training',
        client: 'Data Annotation Tech',
        clientLink: 'https://www.dataannotation.tech/',
        dates: 'Jan. 2024 - Present',
        skillsUsed: ['JavaScript', 'HTML/CSS', 'React', 'Redux', 'RESTful APIs', 'Python', 'Data Structures and Algorithms'],
        image: {
            src: dataAnnotationScreenshot,
            alt: 'Data Annotation Tech Home Page'
        },
        achievements: [
            'Logged 100+ hours of AI traiing and research',
            'Refactored AI-generated code for hundreds of React components',
            'Tested dozens of server/client interactions in AI-generated Node.js',
            'Evaluated efficienncy and accuracy of AI-generated python algorithms'
        ]
    },
    {
        id: 'professional2',
        title: 'API Build for Data Entry Web App',
        client: 'Dwishes (early stage startup)',
        clientLink: 'https://github.com/KeyTurnOps',
        dates: 'Jul. 2023 - Dec. 2023',
        skillsUsed: ['JavaScript', 'Node.js', 'Firebase', 'Cloud Functions', 'RESTful APIs'],
        image: {
            src: dwishesScreenshot,
            alt: 'Dwishes Home Page'
        },
        achievements: [
            'Delivered 7 new features for a data entry web application',
            'Collaborated with the team to establish API endpoints via cloud functions',
            'Connected the React frontend to the API for CRUD functionality'
        ]
    }
]

export const personalProjects = [
    {
        title: "Grandma Sandy's Kitchen",
        description: "A MERN stack web application which houses my grandma's recipes so the whole family can access them. Features include Oauth2 accounts, sessions, comments, and email notifications.",
        dates: 'Nov. 2023 - Present',
        skillsUsed: ['JavaScript', 'HTML/CSS', "React", "React Query", "Oauth2", "Node.js", "Express.js", "MongoDB", "Mongoose", "Git"],
        links: [
            {
                label: 'Demo',
                href: 'https://grandma-8ed4c.web.app/recipes'
            },
            {
                label: 'React Frontend',
                href: 'https://github.com/effieguenther/grandmas-kitchen'
            },
            {
                label: 'Node.js Backend',
                href: 'https://github.com/effieguenther/grandma-server'
            }

        ],
        image: {
            src: gmaScreenshot2,
            alt: "Grandma Sandy's Kitchen Home Page"
        }
    },
    {
        title: "Word of Mouth",
        description: "A collaboratively-built MERN stack social media web application designed to service the unique infrastructure of freelance workers in Nepal. Features include user accounts, comments, and friend requests.",
        dates: 'Jun. 2023 - Nov. 2023',
        skillsUsed: ['JavaScript', 'HTML/CSS', "React", "Redux", "Bootstrap", "Node.js", "Express.js", "MongoDB", "Mongoose", "Git", "Firebase"],
        links: [
            {
                label: 'Demo',
                href: 'https://wordofmouth-alpha.web.app'
            },
            {
                label: 'React Frontend',
                href: 'https://github.com/sruthiravindra/wordofmouth'
            },
            {
                label: 'Node.js Backend',
                href: 'https://github.com/effieguenther/wordofmouth_server'
            }
        ],
        image: {
            src: screenshot1,
            alt: "Word of Mouth Home Page"
        }
    }
]

export const miniProjects = [
    {
        title: 'Learn Firebase',
        description: 'An exploration of the Firebase API basics including authorization, Firestore database, and file storage',
        link: 'https://codesandbox.io/s/learn-firebase-8ks77d'
    },
    {
        title: 'Reactstrap Pagination',
        description: 'Experimentation with Reactstrap pagination and custom CSS styling',
        link: 'https://codesandbox.io/s/reactstrap-pagination-n2kkdf'
    },
    {
        title: 'Learn React Spring',
        description: 'An exploration of the React Spring library basics including useSpring, useTransition, and useTransitionAuto',
        link: 'https://codesandbox.io/s/learn-react-spring-pt1-5w7yyv'
    },
    {
        title: 'Learn react-graph-vis',
        description: 'An exploration of the react-graph-vis library, a React component to display beautiful network graphs using vis.js',
        link: 'https://codesandbox.io/s/learn-react-graph-vis-6h32mj'
    },
    {
        title: 'useState vs. useRef',
        description: "A deep dive into the differences between React's useState and useRef",
        link: 'https://codesandbox.io/s/usestate-vs-useref-h2xxpf'
    },
    {
        title: 'Practice Merge Sort',
        description: 'Practice to understand merge sort with JavaScript',
        link: 'https://codesandbox.io/s/merge-sort-n523sr?file=/src/index.mjs'
    },
    {
        title: 'Practice Quick Sort',
        description: 'Practice to understand quick sort with JavaScript',
        link: 'https://codesandbox.io/s/quick-sort-tsrqjk?file=/src/index.mjs'
    },
    {
        title: 'Practice BFS/DFS',
        description: 'Practice to understand breadth-first-search and depth-first-search with python',
        link: 'https://codesandbox.io/p/sandbox/bfs-and-dfs-gmzx64?file=%2Fmain.py%3A1%2C1'
    },
    {
        title: 'Practice Binary Search',
        description: 'Practice to understand binary search with python',
        link: 'https://codesandbox.io/p/sandbox/binary-search-sqjr2h?file=%2Fmain.py%3A1%2C1'
    }
]

export const womScreenshots = [
    {
        src: screenshot1,
        altText: 'word of mouth screenshot 1'
    },
    {
        src: screenshot2,
        altText: 'word of mouth screenshot 2'
    },
    {
        src: screenshot3,
        altText: 'word of mouth screenshot 3'
    },
    {
        src: screenshot4,
        altText: 'word of mouth screenshot 4'
    },
    {
        src: screenshot5,
        altText: 'word of mouth screenshot 5'
    }
]

export const frScreenshots = [
    {
        src: frScreenshot1,
        altText: 'focus reader screenshot 1'
    },
    {
        src: frScreenshot2,
        altText: 'focus reader screenshot 2'
    },
    {
        src: frScreenshot3,
        altText: 'focus reader screenshot 3'
    }
]

export const abScreenshots = [
    {
        src: abScreenshot1,
        altText: 'asana buddy screenshot 1'
    },
    {
        src: abScreenshot2,
        altText: 'asana buddy screenshot 2'
    },
    {
        src: abScreenshot3,
        altText: 'asana buddy screenshot 3'
    }
]

export const gmaScreenshots = [
    {
        src: gmaScreenshot1,
        altText: 'grandma sandys kitchen screenshot 1'
    },
    {
        src: gmaScreenshot2,
        altText: 'grandma sandys kitchen screenshot 2'
    },
    {
        src: gmaScreenshot3,
        altText: 'grandma sandys kitchen screenshot 3'
    },
    {
        src: gmaScreenshot4,
        altText: 'grandma sandys kitchen screenshot 4'
    },
    {
        src: gmaScreenshot5,
        altText: 'grandma sandys kitchen screenshot 5'
    }
]