import { useSelector } from "react-redux";
import { useTrail, animated } from "@react-spring/web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useLocation, Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useState } from "react";
import { Collapse, CardBody, Card, Col } from "reactstrap";

import "../css/nav.css";

export default function Nav() {
  const isPhone = useMediaQuery({ query: "(max-width: 576px)" });
  const theme = useSelector((state) => state.themes.currentTheme);
  const location = useLocation().pathname;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const socialLinks = [
    {
      icon: faEnvelope,
      label: "Email",
      href: "mailto:effiegguenther@gmail.com",
    },
    {
      icon: faBriefcase,
      label: "Upwork",
      href: "https://www.upwork.com/freelancers/~018f785b41a5272472",
    },
    {
      icon: faGithub,
      label: "GitHub",
      href: "https://github.com/effieguenther",
    },
    {
      icon: faLinkedin,
      label: "LinkedIn",
      href: "https://www.linkedin.com/in/effieguenther/",
    },
  ];

  const trail = useTrail(socialLinks.length, {
    from: { opacity: 0, transform: "translateY(20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { duration: 500 },
    delay: 300,
  });

  if (isPhone)
    return (
      <Col xs={12} className={`navpanel-mobile`}>
        <button onClick={toggle}>
          <FontAwesomeIcon icon={faBars} size="lg" />
        </button>
        <Collapse isOpen={isOpen}>
          <Card>
            <CardBody>
              <div className="navlink-container">
                <Link
                  className={`navlink ${location === "/projects" && "active"}`}
                  to="/projects"
                  onClick={toggle}
                >
                  Projects
                </Link>
                <Link
                  className={`navlink ${location === "/resume" && "active"}`}
                  to="/resume"
                  onClick={toggle}
                >
                  Resume
                </Link>
              </div>
              {socialLinks.map((link, idx) => (
                <a
                  href={socialLinks[idx].href}
                  target="_blank"
                  className="d-flex align-items-center"
                  key={`link-${idx}`}
                >
                  <FontAwesomeIcon icon={socialLinks[idx].icon} size="xl" />
                  <p>{socialLinks[idx].label}</p>
                </a>
              ))}
            </CardBody>
          </Card>
        </Collapse>
      </Col>
    );

  return (
    <Col xs={4} md={3} className="px-0">
      <div className={`${theme} navpanel`}>
        <div className="navpanel-content">
          {location !== "/" && (
            <div className="navlink-container">
              <Link
                className={`navlink ${location === "/projects" && "active"}`}
                to="/projects"
              >
                Projects
              </Link>
              <Link
                className={`navlink ${location === "/resume" && "active"}`}
                to="/resume"
              >
                Resume
              </Link>
            </div>
          )}
          {trail.map((props, idx) => (
            <animated.a
              style={{
                ...props,
                transition: "none",
                animation: "none",
              }}
              href={socialLinks[idx].href}
              target="_blank"
              className="d-flex align-items-center"
              key={`link-${idx}`}
            >
              <FontAwesomeIcon icon={socialLinks[idx].icon} size="xl" />
              <p>{socialLinks[idx].label}</p>
            </animated.a>
          ))}
        </div>
      </div>
    </Col>
  );
}
