import { Button, Row, Col, Container, Collapse } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import headshot from "../assets/img/portfolio_headshot_color.png";
import "../css/header.css";

const Header = () => {
  const isPhone = useMediaQuery({ query: "(max-width: 576px)" });
  const navigate = useNavigate();

  return (
    <Container fluid className={`header`}>
      <Row className={`avatar-container ${isPhone && "d-none"}`}>
        <img src={headshot} alt="Effie Guenther Avatar" className="avatar" />
      </Row>
      <Row className={`header-text ${isPhone && "mobile"}`}>
        <h1 onClick={() => navigate("/")}>Effie Guenther</h1>
      </Row>
    </Container>
  );
};

export default Header;
