import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { useState, useRef, useEffect } from 'react';

export default function UseRefVsUseState() {
  const [count, setCount] = useState(0);
  const countRef = useRef(0);
  const inputRef = useRef();
  const listRef = useRef();

  const handleIncrement = () => {
    setCount(count + 1);
    setCount(count + 1);
  }

  const handleIncrementBy2 = () => {
    setCount(prevCount => prevCount + 1);
    setCount(prevCount => prevCount + 1);
  }

  const handleRefIncrement = () => {
    countRef.current++;
  }

  const focusInput = () => {
    inputRef.current.focus();
    console.log("inputRef.current", inputRef.current);
  }

  const getInfo = () => {
    //element information
    console.log("listRef.current", listRef.current);
    console.log("children", listRef.current.children);
    console.log("next sibling", listRef.current.nextElementSibling);
    console.log("width", listRef.current.clientWidth);
    console.log("height", listRef.current.clientHeight);
    //node information
    console.log("node type", listRef.current.nodeType);
    console.log("parent element", listRef.current.parentElement);
    console.log("has child nodes?", listRef.current.hasChildNodes());
  }

const code1 = `import { useState } from 'react';

export default function Counter() {
  const [count, setCount] = useState(0);

  const handleIncrement = () => {
    setCount(count + 1);
    setCount(count + 1);
  }

  return (
    <div>
      <p>Count: {count}</p>
      <button onClick={handleIncrement}>Increase Count</button>
    </div>
  )
}`

const code2 = `const handleIncrementBy2 = () => {
  setCount(prevCount => prevCount + 1);
  setCount(prevCount => prevCount + 1);
}`

const code3 = `import { useRef } from 'react'

export default function Counter() {
  const countRef = useRef(0);

  const handleRefIncrement = () => {
    countRef.current++;
  }

  return (
    <div>
      <p>Ref Count: {count}</p>
      <button onClick={handleRefIncrement}>Increase Ref Count</button>
    </div>
  )
}`

const code4 = `import { useRef } from 'react';

export default function Focus() {
  const inputRef = useRef();

  const focusInput = () => {
    inputRef.current.focus();
    console.log("inputRef.current", inputRef.current);
  }

  return (
    <div>
      <input ref={inputRef} placeholder="I'm focused"/>
      <button onClick={focusInput} style={{ marginTop: '1em' }}>Focus Input</button>
    </div>
  )
}`

const code5 = `import { useRef } from 'react';
export default function Focus() {

  const getInfo = () => {
    //element information
    console.log("listRef.current", listRef.current);
    console.log("children", listRef.current.children);
    console.log("next sibling", listRef.current.nextElementSibling);
    console.log("width", listRef.current.clientWidth);
    console.log("height", listRef.current.clientHeight);
    //node information
    console.log("node type", listRef.current.nodeType);
    console.log("parent element", listRef.current.parentElement);
    console.log("has child nodes?", listRef.current.hasChildNodes());
  }

  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <p>To do list:</p>
      <ul ref={listRef}>
        <li>Learn React</li>
        <li>Feed the cat</li>
        <li>Take a walk</li>
      </ul>
      <button onClick={getInfo}>Get Info</button>
  </div>
  )
}`

  return (
    <div className='blog-post-container'>
      <h6 className="blog-title">React's useState vs. useRef</h6>
      <p className='blog-text'>
        Two of the most common hooks used in React are useState and useRef. While they are both used to contain
        local state variables, they have some important differences. Let's look at useState first. The useState function
        accepts a parameter of the initial state and returns an array containing the current state and the set function.
        It's commonly declared with array destructuring syntax like so:
      </p>
      <div className='blog-output'>
        <p>Count: {count}</p>
        <button onClick={handleIncrement}>Increase Count</button>
      </div>
      <SyntaxHighlighter wrapLines className='blog-code' language="javascript" style={materialDark} showLineNumbers>
        {code1}
      </SyntaxHighlighter>
      <p className='blog-text'>
        You might notice that there's an error in the code above. Count is increased twice in the handleIncrement
        function, so you would expect the count to be increased by two. However, one of the key behaviors of useState 
        is that the value of the updated variable is only available in the next render. This is why it's usually
        a good idea to pass an updater function into the set function like this:
      </p>
      <div className='blog-output'>
        <p>Count: {count}</p>
        <button onClick={handleIncrementBy2}>Increase Count By 2</button>
      </div>
      <SyntaxHighlighter wrapLines className='blog-code' language="javascript" style={materialDark} showLineNumbers>
        {code2}
      </SyntaxHighlighter>
      <p className='blog-text'>
        By passing an updater function instead of calling upon count directly, a queue is created by React. 
        In the new render, the updater functions will be called in the order they're received.  
      </p>
      <p className='blog-text'>
        And that's the main difference between useState and useRef - useRef doesn't trigger a re-render, so
        any updates to its state are available in the current render of the component. The useRef function accepts
        a parameter of the initial state and returns an object with a single property called "current." Initally,
        the value of current is whatever you pass as the initial state.
      </p>
      <div className='blog-output'>
        <p>Ref Count: {countRef.current}</p>
        <button onClick={handleRefIncrement}>Increase Ref Count</button>
      </div>
      <SyntaxHighlighter wrapLines className='blog-code' language="javascript" style={materialDark} showLineNumbers>
        {code3}
      </SyntaxHighlighter>  
      <p className='blog-text'>
        Unfortunately, no matter how many times you click the "Increase Ref Count" button, the new count
        will not display on the screen. At least, not until something else triggers a re-render (try 
        clicking one of the useState incrementers above to trigger a change). It doesn't mean the count 
        isn't being increased - you just can't see it.
      </p>      
      <p className='blog-text'>
        When I first learned about these two hooks, I wondered why you wouldn't just choose useState all
        the time. After all, useRef in the previous example isn't very helpful. However, the useRef hook 
        can be very handy if you want to manipulate DOM elements. With the ref prop, you can assign a useRef
        object to an element and the current property will be populated with the DOM node. 
      </p>
      <div className='blog-output'>
        <input ref={inputRef} placeholder="I'm focused"/>
        <button onClick={focusInput} style={{ marginTop: '1em' }}>Focus Input</button>
      </div>
      <SyntaxHighlighter wrapLines className='blog-code' language="javascript" style={materialDark} showLineNumbers>
        {code4}
      </SyntaxHighlighter>
      <p className='blog-text'>
        In the example above, useRef is assigned to an input element. A practical use case would be to call the  
        focusInput function inside a useEffect hook to focus the cursor on the input when the page first loads. 
        By hooking into the DOM node, you gain a LOT of control over an element. You can view any property or use any methods which exist
        in the <a target="blank" href="https://developer.mozilla.org/en-US/docs/Web/API/Element">Element class</a> 
        or <a href="https://developer.mozilla.org/en-US/docs/Web/API/Node" target="_blank">Node API</a>. Open your console
        to interact with this small example:
      </p>
      <div className='blog-output'>
        <div style={{ alignSelf: 'flex-start' }}>
          <p>To do list:</p>
          <ul ref={listRef}>
            <li>Learn React</li>
            <li>Feed the cat</li>
            <li>Take a walk</li>
          </ul>
          <button onClick={getInfo}>Get Info (in console) </button>
        </div>
      </div>
      <SyntaxHighlighter wrapLines className='blog-code' language="javascript" style={materialDark} showLineNumbers>
        {code5}
      </SyntaxHighlighter>
    </div>
  )
}
