import {
  Row,
  Col,
  Button,
  Card,
  CardTitle,
  CardText,
  Container,
  Carousel,
  CarouselItem,
  CarouselControl,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTransition, animated, easings } from "@react-spring/web";
import ChromeExtension from "../chrome-extension/ChromeExtension";
import { professionalProjects, personalProjects } from "./project-data";
import "../../css/projects.css";

const Projects = () => {
  const theme = useSelector((state) => state.themes.currentTheme);
  // const [womActiveIdx, setWomActiveIdx] = useState(0);
  // const [frActiveIdx, setFrActiveIdx] = useState(0);
  // const [abActiveIdx, setAbActiveIdx] = useState(0);
  // const [gmaActiveIdx, setGmaActiveIdx] = useState(0);

  const transition = useTransition(true, {
    from: { opacity: 0.5, transform: "translate3d(60vw, 0, 0)" },
    enter: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    leave: { opacity: 0, transform: "translate3d(-100vw, 0, 0)" },
    config: { duration: 400, easing: easings.easeOutSine },
    exitBeforeEnter: true,
  });

  return transition(
    (style, item) =>
      item && (
        <animated.div style={style} className={theme}>
          <div className="project-header">
            <h4>Professional Projects</h4>
            <hr />
          </div>
          {professionalProjects.map(
            (project) =>
              project && (
                <Row key={project.id} className="project-container">
                  <Col xs={12} lg={6} className="mb-4">
                    <img
                      className="project-pic"
                      src={project.image.src}
                      alt={project.image.alt}
                    />
                  </Col>
                  <Col>
                    <p className="project-title">{project.title}</p>
                    <hr />
                    <p className="project-client">
                      Client:{" "}
                      <a href={project.clientLink} target="_blank">
                        {project.client}
                      </a>
                    </p>
                    <p className="project-dates">{project.dates}</p>
                    <p className="project-skills">
                      {project.skillsUsed.map((skill, idx) => {
                        if (idx < project.skillsUsed.length - 1) {
                          return (
                            <span key={skill + project.id}>{`${skill}, `}</span>
                          );
                        } else {
                          return (
                            <span key={skill + project.id}>{`${skill}`}</span>
                          );
                        }
                      })}
                    </p>
                    <ul className="achievement-list">
                      {project.achievements.map((achievement, idx) => {
                        return (
                          <li key={project.id + achievement + idx}>
                            {achievement}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )
          )}
          <div className="project-header">
            <h4>Personal Projects</h4>
            <hr />
          </div>
          {personalProjects.map(
            (project) =>
              project && (
                <Row key={project.id} className="project-container">
                  <Col>
                    <p className="project-title">{project.title}</p>
                    <hr />
                    <p className="project-description">{project.description}</p>
                    <p className="project-dates">{project.dates}</p>
                    <div className="project-links">
                      {project.links.map((link) => (
                        <a
                          href={link.href}
                          className="project-link"
                          target="_blank"
                        >
                          {link.label}
                        </a>
                      ))}
                    </div>
                    {/* <p className='project-skills'>
                    {
                      project.skillsUsed.map((skill, idx) => {
                        if (idx < project.skillsUsed.length - 1) {
                          return <span key={skill + project.id}>{`${skill}, `}</span>
                        } else {
                          return <span key={skill + project.id}>{`${skill}`}</span>
                        }
                      })
                    }
                  </p> */}
                  </Col>
                  <Col
                    xs={12}
                    lg={6}
                    className="order-first order-lg-last mb-4"
                  >
                    <img
                      className="project-pic"
                      src={project.image.src}
                      alt={project.image.alt}
                    />
                  </Col>
                </Row>
              )
          )}
        </animated.div>
      )
  );
};

export default Projects;
