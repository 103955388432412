import { blogData } from "./blogData"
import './blog.css';

export default function BlogList() {
  return (
    <div>
      {
        blogData.map(post => <post.component key={post.id} />)
      }
    </div>
  )
}
